import { Component } from '@angular/core';
import { MenuController,PopoverController } from '@ionic/angular';
import { Router, NavigationStart  } from '@angular/router';
import { ProfileComponent } from './profile/profile.component'
import { AngularEditorModule } from '@kolkov/angular-editor';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  username:any;
  waba_id:any;
  phone_number_id:any;
  businessId:any;

  showHead: boolean = false;
  constructor(private menu: MenuController,private router: Router,public popoverController: PopoverController) {
    this.initializeApp();
    // on route change to '/login', set the variable showHead to false
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/' || event['url'] == '/otp') { // login route should be empty else header will hide only refresh.
          this.showHead = false;
        } else {
          // console.log("NU")
          this.showHead = true;
        }
      }
    });
  }

  async profile(ev: any) {  
    const popover = await this.popoverController.create({  
        component: ProfileComponent,  
        event: ev,  
        animated: true,  
        showBackdrop: true
    });  
    return await popover.present();  
  } 

 ngOnInit(){
  
 }

 initializeApp(){
  
 }

 ionViewWillEnter(){
  debugger;
  this.username = localStorage.getItem('username');
  this.waba_id = localStorage.getItem('whatsapp_businessId');
  this.phone_number_id = localStorage.getItem('phone_no_Id');
  this.businessId = localStorage.getItem('businessId');
 }

 logout(){
  localStorage.clear()
  this.router.navigateByUrl('/');
 }
  
}
