import { Component } from '@angular/core';
import { MenuController,PopoverController } from '@ionic/angular';
import { Router, NavigationStart  } from '@angular/router';
import { ProfileComponent } from './profile/profile.component'
import { AngularEditorModule } from '@kolkov/angular-editor';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  showHead: boolean = false;
  constructor(private menu: MenuController,private router: Router,public popoverController: PopoverController) {
    // on route change to '/login', set the variable showHead to false
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/' || event['url'] == '/login' || event['url'] == '/otp') { // login route should be empty else header will hide only refresh.
          this.showHead = false;
        } else {
          // console.log("NU")
          this.showHead = true;
        }
      }
    });
  }

  async profile(ev: any) {  
    const popover = await this.popoverController.create({  
        component: ProfileComponent,  
        event: ev,  
        animated: true,  
        showBackdrop: true
    });  
    return await popover.present();  
  } 

 ngOnInit(){
  
 }

 logout(){
  this.router.navigateByUrl('/');
 }
  
}
